
import React from 'react'
import { Dialog } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import auth from '../../auth';
import { useHistory } from 'react-router';
import styles from './exitmodal.module.scss'
type ExitModalProps = {
    openModal:boolean
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}
export default function ExitModal(props:ExitModalProps) {
    const {openModal, setOpenModal} = props 
    const handleClose = () => {
        setOpenModal(false)
    }
    const history = useHistory()

    const onLogout = () => {
        auth.logout()
        history.push('/login')
    }
    return (
        <Dialog
            onClose={handleClose}
            open={openModal}
        >
            <div className={styles.container}>
                <h1>Вы уверены, что хотите выйти?</h1>
            </div>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary" autoFocus>
                    Отмена
                </Button>
                <Button onClick={() => onLogout()} color="primary">
                    Да
                </Button>
        </DialogActions>
        </Dialog>
    )
}
