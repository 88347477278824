import { authResponseType} from "../../types";

export enum UserActionTypes {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
}


export interface LoginAction {
    type: UserActionTypes.LOGIN
    payload: authResponseType
}

export interface LogoutAction {
    type: UserActionTypes.LOGOUT
}

export type UserActions = LoginAction | LogoutAction


export type authStates = {
    tokens: null | authResponseType
}