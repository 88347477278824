import TestPage from './pages/TestPage'
 
import {routesType} from './types'
import Template from './pages/Tempates'
import Login from './pages/Login'
import PageNotFound from './pages/PageNotFound'



export const routes:routesType[] = [
    
    {
        path: '/',
        component: TestPage,
        auth: {
            navbar:{
                title: 'Главаная',
                show: false
            }
        }
    },
    {
        path: '/login',
        component: Login,
        auth: false
    },
    {
        path: '/template',
        component: Template,
        auth: {
            navbar:{
                title: 'Шаблоны',
                exact: false
                
            }
        }
    },
    
    {
        path: '404',
        component: PageNotFound,
        auth: {
            navbar:{
                exact: false,
                show: false
                
            }
        }
    },
   
]