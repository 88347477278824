import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
type modalProps = {
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
    openModal: boolean
    preview: string | null
    loadingPreview: boolean
}


export default function Modal(props: modalProps) {
    const useStyles = makeStyles(() => ({
        paper: { minWidth: "850px" },
      }));


    const classes = useStyles()
    const {openModal, setOpenModal, preview} = props
    const handleClose = () => {
        setOpenModal(false);
      };
    
    // if(loadingPreview){
    //     return (
    //         <CircularProgress />
    //     )
    // }
     
    return (
        <Dialog
        open={openModal}
        onClose={handleClose}
        classes={{paper:classes.paper}}
      >
        <DialogTitle id="alert-dialog-title">Предварительный просмотр</DialogTitle>
        {
            preview && (
                <div dangerouslySetInnerHTML={{__html: preview}}/>
            )
        }
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    )
}
