import React, { FC } from 'react'
import { Redirect, Route } from 'react-router'
import auth from '../../auth'
import HeaderPanel from '../HeaderPanel'

type ProtectedRouteProps = {
    component: FC 
    [x: string]: any
    Navbar?: FC
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
    const {access, Navbar, component: Component, ...rest} = props

    const renderComponent = (props:any) => {
        if(Navbar){
          return(
            <div style={{display: 'flex'}}>
              <Navbar/>
              <HeaderPanel>
                <Component {...props}/>
              </HeaderPanel>
            </div>
          )
        }
        else{
          return <Component {...props} />
        }
      }

      const onAuth = (props:any) => {
          
            if (auth.isAuthenticated()) {
                if(props.location.pathname === '/'){
                  return <Redirect to={{ pathname: "/template", state: { from: props.location } }} />
                }
                return renderComponent(props)
            } 
            else {
                return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            }
        }

    return (
        <Route
            {...rest}
            render={onAuth}
        />
    )
}
