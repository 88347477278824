import { api } from "./core/api";
import { authFormTypes, authResponseType } from "./types";

class Auth {
   async login(value:authFormTypes){
        const { data } = await api.post<authResponseType>('auth/login', {
            login: value.login,
            password: value.password,
        }).catch(() => {
            throw new Error('Неверный логин или пароль')
        })

        if (!data.accessToken) {
            throw new Error('Не удаётся выполнить вход в систему')
        }

        localStorage.setItem('accessToken',data.accessToken)
   }

   logout() {
        localStorage.removeItem('accessToken')
   }

   isAuthenticated() {
       return !!localStorage.getItem('accessToken')
   }
}

export default new Auth();

