import { authStates, UserActions, UserActionTypes } from "./authTypes"

const {LOGIN, LOGOUT} = UserActionTypes





const InitialState: authStates = {
    tokens: null,
}


export const authReducer = (state = InitialState, action:UserActions):authStates => {
    switch(action.type){
        case LOGIN:
            return {...state, tokens: action.payload}
        case LOGOUT:
            return {...state, tokens: null}
        default:
            return state
    }
}


