import React, {useEffect, useState, useRef} from 'react'
import { Button, TextField } from '@material-ui/core'
import { FieldArray, Formik, FormikProps } from 'formik'
import styles from './form.module.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { productsTypes, formInitialValues, letterPreviewRequest, sendEmailRequest } from '../../../types'
import { api } from '../../../core/api'
import * as yup from 'yup'
import Modal from '../../../components/Modal'
 
import { useSnackbar, VariantType } from 'notistack';

const ReviewSchema = yup.object({
    recipientsFile: yup.mixed(),
    recipientsList: yup.string(),
    header: yup.string().required(),
    subject: yup.string().required(),
    message: yup.string().required(),
    banner:  yup.mixed().required(),
 
    products: yup.array().of(
        yup.object().shape({
            category: yup.string(),
          
            selected: yup.object().shape({
                old_price: yup.number().required(),
                price:  yup.number().required(),
                thumb:  yup.string().required(),
                title: yup.string().required(),
                url:  yup.string().required()
            }).required()
        })
    )
   
  })

export default function Form() {
    const [category, setCategory] = useState<null | string[]>(null)
 
    const [changeCategory, setChangeCategory] = useState<string>('')
    const [loading, setloading] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false)
 
    const xlsFileref = useRef<any>()
    const bannerFile = useRef<any>()
 


    const [preview, setPriview] = useState<string | null>(null)
    const [loadingPreview, setloadingPreview] = useState<boolean>(false)

    const [bannerImgKey, setBannerImgKey] = useState<string | null>(null)
    const [bannerImgKeyError, setBannerImgKeyError] = useState<string | null>(null)

    const [emailFileKey, setEmailFileKey] = useState<string | null>(null)
    const [emailFileKeyError, setEmailFileKeyError] = useState<string | null>(null)

    const { enqueueSnackbar } = useSnackbar()

    const handleClickVariant = (variant: VariantType, text: string) => {
        enqueueSnackbar(text, { variant });
      };

    useEffect(() => {
        setloading(true)
         api.get('marketing/preload-catalog')
         .catch(e => {
             handleClickVariant('error', 'Неизвестная ошибка! Обратитесь к администратору')
             console.error(e)
         })

         api.get<string[]>('marketing/categories')
         .then(({data})=>{
             data.unshift('Все')
            setCategory(data)
         })
         .catch((e)=>{
            handleClickVariant('error', 'Ошибка! Не удалось загрузить список категорий')
            console.error(e)
         })
        //  .catch(e => console.error(e))
         setloading(false)

    }, []);

    const deleteXlsFile = (props?: FormikProps<formInitialValues>) => {
        if(xlsFileref !== undefined){
            xlsFileref.current.value = ''
            props&& props.setFieldValue('recipientsFile', null)

        }
    }
    const deleteBannerFile = () => {
        if(bannerFile !== undefined){
            bannerFile.current.value = ''
        }
    }

    const getProducts = async (category:string) => {

        try{

            const url = category === 'Все' ? `marketing/products` : `marketing/products?category=${category}`
            const {data} = await api.get<productsTypes[]>(url)
            return data
        }catch(e){
            // console.error(e)
        }



    }

    useEffect(() => {
        getProducts(changeCategory)
    }, [changeCategory]);



    const getPreview = (props: letterPreviewRequest) => {
        api.post('marketing/preview/catalog-letter', props)
        .then(({data})=>setPriview(data))
        .then(() => setOpenModal(true))
        .catch( e => console.error(e))

    }

    
    const sendEmailFile = async (file:File) => {
        const formData = await new FormData()
        formData.append('file',file)
        return api.post<{fileKey: string}>('files/upload/emails',formData)
        .then(({data})=>{
            // console.log('sendEmailFile',data)
            setEmailFileKey(data.fileKey)
            return data
        })
        .catch(e => {
            // console.error(e)
            handleClickVariant('error', 'При загрузке таблицы что-то пошло не так')
            setEmailFileKeyError(`Ошибка, файл не загрузился`)
            throw new Error('Error')
        })
    }

    const sendBannerImg = async(file:File) => {
        const formData = await new FormData()
        formData.append('file',file)
         
        return api.post<{fileKey: string}>('files/upload/banners',formData)
        .then(({data})=>{
            // console.log('sendBannerImg',data)
            setBannerImgKey(data.fileKey)
            return data
        })
        .catch(e => {
            // console.error('sendBannerImg',e)
            handleClickVariant('error', 'Что-то пошло не так при загрузке баннера')
            setBannerImgKeyError('Ошибка')
            throw new Error('Error')
        })
    }



    const sendPromoEmail = async (props:sendEmailRequest) => {
       await api.post<{processingCount: number}>('marketing/send/catalog-letter', props)
        .then(({data})=>{
            // console.log('sendPromoEmail',data)
            handleClickVariant('success', `Отправляется ${data.processingCount && data.processingCount}`)
        })
        .catch((e) => {
            // console.log(e)
            handleClickVariant('error', 'Ошибка, форма не отправлена')
        })
    }

    const showPreview = async (value:formInitialValues) => {
        setloadingPreview(true)
        const newProducts = await value.products.map(item => {
            return item.selected
        })
        
            value.banner && await sendBannerImg(value.banner)
            .then((banner)=>{
                  getPreview({
                    subject: value.subject,
                    header: value.header,
                    text: value.message,
                    bannerFileKey: banner.fileKey,
                    products: newProducts
                })

            })
            .catch(e => console.error(e))
        
        setloadingPreview(false)
        
    }


 
    const initialValues: formInitialValues = {
        recipientsFile: null,
        recipientsList: '',
        header: '',
        subject: '',
        message: '',
        banner: null,
        products: [
            {
                category: '',
                selected: '',
                data: null
            },
            {
                category: '',
                selected: '',
                data: null
            },
            {
                category: '',
                selected: '',
                data: null
            },
            {
                category: '',
                selected: '',
                data: null
            },
            {
                category: '',
                selected: '',
                data: null
            },
            {
                category: '',
                selected: '',
                data: null
            },
            
             
        ],
    }

    const onchangeBanner = (event:React.ChangeEvent<HTMLInputElement>, props:FormikProps<formInitialValues>) => {

        if(event.currentTarget.files !== null){
            if(event.currentTarget.files[0].size < 3000000){
                props.setFieldValue('banner', event.currentTarget.files[0])
                setBannerImgKeyError('')
            }
            else{
                props.setFieldValue('banner', null)
                setBannerImgKeyError('Ошибка. большой размер файла')
                deleteBannerFile()
            }
        } 
        
    }


 
 
    if(loading){
        return (
            <div className={styles.loading}>
                <CircularProgress />
            </div>
        )
    }
  
    return (
        <>
        <Modal 
            openModal={openModal} 
            preview={preview} 
            setOpenModal={setOpenModal}
            loadingPreview={loadingPreview}
        />
 

        <Formik
            initialValues={initialValues}
            validationSchema={ReviewSchema}
            onSubmit={async (value, actions)=>{
                
                actions.setSubmitting(true)
                // console.log(value)
                

            
                
                const emails =  value.recipientsList.replace(/\s/g, '').trim()
                // console.log('emails',emails)
                const emailsList = await emails.split(',')
                // console.log(emailsList)

                const newProducts = await value.products.map(item => {
                    return item.selected
                })
                // send email with xls
                if(value.recipientsFile !== null){
                    value.recipientsFile && 
                    sendEmailFile(value.recipientsFile)
                    .then((xlsFile) => {
                        // console.log('recipientsFile promise ',xlsFile)
                        value.banner &&   
                        sendBannerImg(value.banner)
                        .then((banner)=>{
                            const sendData = {
                                subject: value.subject.toString(),
                                header: value.header.toString(),
                                text: value.message.toString(),
                                bannerFileKey: banner.fileKey.toString(),
                                emails: emailsList,
                                products: newProducts,
                                emailsFileKey: xlsFile.fileKey
                            }
                            sendPromoEmail(sendData)
                        })
                        .catch((e)=>console.error(e))
                    })
                    .catch(e => console.error(e))
                }
                  // send email 
                else{
                    value.banner &&  
                    sendBannerImg(value.banner)
                    .then((data)=>{
                        const sendData = {
                            subject: value.subject.toString(),
                            header: value.header.toString(),
                            text: value.message.toString(),
                            bannerFileKey: data.fileKey.toString(),
                            emails: emailsList,
                            products: newProducts,
                        }
                        sendPromoEmail(sendData)
                    })
                    .catch(e => console.error(e))
                }
               
            
                deleteXlsFile()
                deleteBannerFile()
                actions.setFieldValue('recipientsFile', null)
                actions.setFieldValue('banner',null)
                actions.setFieldValue('recipientsList','')
                actions.setSubmitting(false)
                
            }}
           
        >
         
            {(props)=>{
                    // console.log('product',props.values)
                   
                    const onDisabled = () => {
                        if(props.values.recipientsFile === null && props.values.recipientsList.length === 0){
                            return props.values.recipientsFile === null && props.values.recipientsList.length === 0
                        }
              
                        return !(props.isValid && props.dirty)
                    }
                return (
                    <div className={styles.container}>
                        {/* <div className={styles.logo}>
                            <img src={logo} alt="logo" />
                        </div> */}
                        <h1 className={styles.title}>
                            Рассылка по товарам недели
                        </h1>
                        <div className={styles.inputGroup}>
                            <div className={styles.file}>
                                <h3>Получатели</h3>
                                <label 
                                    className={styles.recipientsFile} 
                                    htmlFor="recipientsFile"
                                >
                                    Выберите файл xlsx или xls
                                </label>
                                <input 
                                
                                    type="file" 
                                    name="recipientsFile" 
                                    id="recipientsFile" 
                                    accept=".xlsx, .xls"
                                    ref={xlsFileref}
                                    onChange={(event:React.ChangeEvent<HTMLInputElement>)=>{
                                        event.currentTarget.files !== null &&
                                        props.setFieldValue('recipientsFile', event.currentTarget.files[0])

                                    }}
                                />
                                <button onClick={()=>deleteXlsFile(props)}>
                                    удалить
                                </button>
                                {
                                    emailFileKeyError && (
                                        <p className="error">{emailFileKeyError}</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className={styles.inputGroup}>
                            <TextField
                                value={props.values.recipientsList}
                                label="Получатели"
                                onChange={props.handleChange('recipientsList')}
                                variant="outlined" 
                                error={Boolean(props.errors.recipientsList && props.touched.recipientsList)}
                                className={styles.input}
                                helperText="Загрузите файл xls или введите список адресов через запятую "
                                multiline
                                rows={4}
                                rowsMax="Infinity"

                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <TextField
                                value={props.values.subject}
                                label="Тема письма"
                                onChange={props.handleChange('subject')}
                                variant="outlined" 
                                error={Boolean(props.errors.subject && props.touched.subject)}
                                className={styles.input}
                                required
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <TextField
                                value={props.values.header}
                                label="Заголовок письма"
                                onChange={props.handleChange('header')}
                                variant="outlined" 
                                error={Boolean(props.errors.header && props.touched.header)}
                                className={styles.input}
                                required
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <TextField
                                value={props.values.message}
                                label="Сообщение"
                                onChange={props.handleChange('message')}
                                variant="outlined" 
                                error={Boolean(props.errors.message && props.touched.message)}
                                className={styles.input}
                                required
                                multiline
                                rows={6}
                                rowsMax="Infinity"
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <div className={styles.file}>
                                <h3>Баннер</h3>
                                <label htmlFor="banner"/>
                                <input 
                                    required
                                    type="file" 
                                    name="banner" 
                                    id="banner"
                                    ref={bannerFile} 
                                    accept=".jpg, .jpeg, .png"
                                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => onchangeBanner(e,props)}
                                />
                                {
                                    bannerImgKeyError && (
                                        <p className="error">{bannerImgKeyError}</p>
                                    )
                                }
                          
                            </div>
                        </div>
                        <div className={styles.products}>
                            <h4>Продукты</h4>
                            <FieldArray name="products">
                                {
                                    (arrayHelpers) => {
                                        const {} = arrayHelpers
                                       
                                    
                                        return  props.values.products.map((item, index)=>{
                                        
                                            
                                            const product = props.values.products[index]
                                            return (
                                                <div 
                                                    className={classNames(styles.inputGroup, styles.selectGroup)} 
                                                    key={index}
                                                >
                                                    {
                                                        category && (
                                                        <Autocomplete
                                                            options={category}
                                                            getOptionLabel={(category) => category}
                                                            style={{ width: 500 }}
                                                            disableClearable
                                                            inputValue={product.category ? product.category: ''}
                                                            className={styles.select}
                                                            onChange={(e,value) => {
                                                                props.setFieldValue(`products.${index}.category`, value)
                                                                if(value){
                                                                    getProducts(value)
                                                                    .then((data)=>{
                                                                        props.setFieldValue(`products.${index}.data`, data)
                                                                    })
                                                                }
                                                                
                                                                
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField 
                                                                    {...params} 
                                                                    label="Категории" 
                                                                    variant="outlined" 
                                                                    required
                                                                    // value={undefined}
                                                                   
                                                                />
                                                            )}
                                                        />
                                                        )
                                                    }
                                                    {
                                                        product.data !== null ? (
                                                            <Autocomplete
                                                                options={product.data}
                                                                getOptionLabel={(products) => products.title}
                                                                style={{ width: 500 }}
                                                                disableClearable
                                                                onChange={(e,value) => {
                                                                    props.setFieldValue(`products.${index}.selected`, value)
                                                                }}
                                                                renderInput={(params) =>(
                                                                    <TextField 
                                                                        {...params} 
                                                                        label="Товары" 
                                                                        variant="outlined" 
                                                                        required
                                                                       
                                                                    />
                                                                )}
                                                            />
                                                        )
                                                        :
                                                        (
                                                            <p>Выберите категорию</p>
                                                        )
                                                    }
                                               
                                                    
                                                </div>
                                                )
                                        })
                                    }
                                }
                            </FieldArray>

                        </div>
                   
                        <Button 
                            className={styles.submit} 
                            variant="contained" 
                            color="primary" 
                            onClick={()=>props.handleSubmit()}
                            style={{backgroundColor: 'green'}}
                            disabled={onDisabled()} 
                            >
                            Отправить 
                            {
                            props.isSubmitting &&
                            <CircularProgress style={{color:'#fff', marginLeft: 15}}  size={14}/>
                            }
                            
                        </Button>
                        <Button 
                            disabled={!(props.isValid && props.dirty)} 
                            variant="outlined"  
                            color="primary"
                            
                            onClick={async ()=>{
                                await showPreview(props.values)
 
                            }
                        }>
                            Предварительный просмотр
                        </Button>

                        
                       
                    </div>

                )
            }}
        </Formik>
        </>
    )
}
