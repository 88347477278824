import React from 'react'
import { Route, Switch, useHistory, withRouter } from 'react-router'
 
import MailingByProductsWeek from '../MailTemplates/MailingByProductsWeek'
import SingleBannerTemplate from '../MailTemplates/SingleBannerTemplate'
import NY from '../MailTemplates/NY'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import styles from './template.module.scss'
 function Templates() {
    const [age, setAge] = React.useState('');
    const history = useHistory()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const route =  event.target.value as string
    history.push(`/template${route}`)
    setAge(event.target.value as string);
    };
    return (
        <div className={styles.container}>
        <div className={styles.selectBlock}>
            <FormControl variant="outlined" className={styles.select}>
                <InputLabel>Выберите шаблон рассылки</InputLabel>
                <Select
                    value={age}
                    onChange={handleChange}
                    label="Выберите шаблон рассылки"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={'/MailingByProductsWeek'}>Рассылка по товарам недели</MenuItem>
                    <MenuItem value={'/SingleBannerTemplate'}>Шаблон с одним баннером</MenuItem>
                    <MenuItem value={'/NY'}>NY 2024</MenuItem>
            
                </Select>
            </FormControl>
        </div>

 
        <div className={styles.templates}>
            <Switch>
                <Route exact path="/template/" render={() => <NotSelected/>}/>
                <Route exact path="/template/MailingByProductsWeek" render={() => <MailingByProductsWeek/>} />
                <Route exact path="/template/SingleBannerTemplate" render={() => <SingleBannerTemplate/>} />
                <Route exact path="/template/NY" render={() => <NY/>} />
            </Switch>
        </div>
        
        </div>
    )
}

const NotSelected = () => {
    return (
        <div className={styles.notSelected}>
            Шаблон не выбран
        </div>
    )
}


export default withRouter(Templates)
