import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';

import { Provider } from 'react-redux';
import { store } from './redux/configureStore';

ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
        <SnackbarProvider  
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            maxSnack={3}
        >
              
          <App />
        </SnackbarProvider>
    </Provider>

  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
