import React from 'react'
import styles from './headerPanel.module.scss'

type headerPanelProps = {
    children: React.ReactNode
}
export default function HeaderPanel({children}:headerPanelProps) {
    return (
        <div className={styles.conteiner}>
            {children}
        </div>
    )
}
