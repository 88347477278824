import React from 'react';
 
import './App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
 
 
import Navbar from './components/Navbar'
import {routes} from './routes'
import PageNotFound from './pages/PageNotFound';

function App() {
  return (
    <div>
      
      <BrowserRouter>
      <Switch>
      
        {
          routes.map((item, index)=>{
            if(item.path === '404'){
              return (
                  <ProtectedRoute 
                    key={index.toString()}
                    Navbar={Navbar} 
                    component={PageNotFound}
                  />
              )  
            }
            if(item.auth){
              return (
                <ProtectedRoute 
                  Navbar={Navbar} 
                  exact={item.auth.navbar.exact !== false}
                  path={item.path} 
                  component={item.component}
                  key={index}
                />
              )
            }
            else{
              return (
                <Route
                  exact 
                  path={item.path} 
                  component={item.component}
                  key={index}
                />
              )
            }
          })
        }
 
      </Switch>
      </BrowserRouter>
    </div>


  );
}

export default App;
