import axios from "axios";
const { REACT_APP_API } = process.env



const api = axios.create({
    baseURL: REACT_APP_API,
    headers: {
        'Content-Type': 'application/json',
    }
})

api.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

export { api }