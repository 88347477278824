import React, {useState} from 'react'
import styles from './navbar.module.scss'
import Logo from '../../assets/logo.png'
import { NavLink, Link } from 'react-router-dom'
import {routes} from '../../routes'
import ExitModal from '../ExitModal/index'
export default function Navbar() {
    const [openModal, setOpenModal] = useState<boolean>(false)
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src={Logo} alt=""/>
                <div className={styles.headerTitles}>
                    <h3>Ecomarket.ru</h3>
                    <h4>Маркетинг</h4>
                </div>
            </div>
            <div className={styles.nav}>
                <div className={styles.navLinks}>
                    <ul>
                        {
                            routes.map((item, index) => {
                                if(item.auth && item.auth.navbar.show !== false){
                                    return (
                                        <li key={index}>
                                            <NavLink
                                                className={styles.link}
                                                activeClassName={styles.selected}
                                                exact={!!item?.auth.navbar.exact}
                                                strict
                                                to={item.path}
                                                key={index}
                                            >
                                                <span>{item.auth.navbar.title}</span>
                                            </NavLink>
                                        </li>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </ul>
                </div>

                <div className={styles.exit}>
                    <ul>
                        <li onClick={()=>setOpenModal(true)}>
                            <Link
                                className={styles.link}
                                to='#'
                            >
                                <span>Выход</span>
                            </Link>
                        </li>
                    </ul>
                </div>

            </div>
            <ExitModal openModal={openModal} setOpenModal={setOpenModal}/>
           
        </div>
    )
}
