import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { authReducer } from './auth/authReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { Transform, WebStorage } from 'redux-persist/es/types'
 


 

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],

}
  
  
const rootReducer = combineReducers({
    auth: authReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)), )
export const persistor = persistStore(store)

 