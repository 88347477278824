import React, { useState } from 'react'
import styles from './login.module.scss'
import {Formik} from 'formik'
import * as yup from 'yup'
import TextField from '@material-ui/core/TextField';
import logo from '../../assets/logo.png'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom'
import auth from '../../auth';

const ReviewSchema = yup.object({
    login: yup.string().required(),
    password: yup.string().required(),
})

export default function Login() {
    const history = useHistory()
    const [error, setError] = useState('')
    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <div className={styles.innerBlock}>

                <div className={styles.header}>
                    <img className={styles.header_logo} src={logo} alt=""/>
                    <div className={styles.header_title}>
                        <h3>Ecomarket.ru</h3>
                        <h4>Маркетинг</h4>
                    </div>
                </div>
                    
                    <Formik
                        initialValues={{
                            login: '',
                            password: ''
                        }}
                        validationSchema={ReviewSchema}
                        validateOnBlur
                        onSubmit={(value, actions)=>{
                            actions.setSubmitting(true)
                            auth.login({
                                login: value.login,
                                password: value.password
                            }).then(() => {
                                history.push('/')
                            }).catch((error)=> setError(error.message))
                            actions.setSubmitting(false)
                        }}
                    >
                        {(props)=>{
                            return (
                                <div className={styles.form}>
                                    <h2>Авторизация</h2>
                                    <div className={styles.inputBlock}>
                                        <TextField
                                            label="Логин"
                                            variant="outlined"
                                            className={styles.input}
                                            inputProps={{className:styles.inputText}}
                                            error={Boolean(props.errors.login && props.touched.login)}
                                            onChange={props.handleChange('login')}
                                        />
                                        <TextField 
                                            id="outlined-basic" 
                                            label="Пароль" 
                                            type="password"
                                            variant="outlined" 
                                            className={styles.input}
                                            value={props.values.password}
                                            error={Boolean(props.errors.password && props.touched.password)}
                                            inputProps={{className:styles.inputText}}
                                            onChange={props.handleChange('password')}
                                        />
                           
                                        <div className={styles.error}>
                                            {error}
                                        </div>
                                        <button 
                                            type="submit"
                                            onClick={() =>props.handleSubmit()}
                                            className={!(props.dirty) || props.isSubmitting ? styles.disabled : ''}
                                            //disabled={!(props.dirty) || props.isSubmitting}
                                        >
                                            {
                                                props.isSubmitting ? 
                                                <CircularProgress size={18} style={{color: '#fff'}}/>
                                                :
                                                'Войти'
                                            }
                                            
                                        </button>
                                    </div>
                                </div>
                            )
                        }}

                    </Formik>
            </div>
            </div>

        </div>
    )
}
